import React from 'react';

// libraries
import { css } from '@emotion/core';
import { Fragment } from 'react';

import Head from 'components/Head';
import Markdown from 'components/Markdown';
import MainLayout from 'components/MainLayout';
import Table from 'components/Table';

import DocsLayout from './Layout';

interface Props {
  pageContext: { value: Struct };
}

export default function StructDocs(props: Props) {
  const { value } = props.pageContext;

  const members = value.members.filter(member => member.brief);

  return (
    <MainLayout>
      <Head title={value.id} />
      <DocsLayout title={value.id}>
        <Markdown forceBlock>{value.brief || ''}</Markdown>
        <Markdown forceBlock>{value.details}</Markdown>
        {members.length ? (
          <Fragment>
            <h2 css={heading}>Fields</h2>
            <Table
              css={{ width: '100%' }}
              columns={['Type', 'Name', 'Description']}
              data={members.map(member => [
                <code>
                  <Markdown>{member.type}</Markdown>
                </code>,
                <code>
                  <Markdown>{member.name}</Markdown>
                </code>,
                <Markdown>{member.brief || ''}</Markdown>
              ])}
            />
          </Fragment>
        ) : null}
      </DocsLayout>
    </MainLayout>
  );
}

const heading = css`
  margin: 4rem 0 2rem;
`;
